import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import reducers from "./Reducers";


import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";


const persistConfig = {
    key: "root",
    version: 1,
    storage,
};
const persistedReducer = persistReducer(persistConfig, reducers);


export const configStore = configureStore({
    reducer: {
        reducer: persistedReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({ serializableCheck: false }).concat([]),
});
export default configStore;
